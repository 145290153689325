<template>
  <div>
    <v-card elevation="1">
      <v-sheet class="d-flex pa-5">
        <div class="d-flex">
          <v-btn
            color="primary"
            outlined
            @click="$router.push({name: 'invoice-new'})"
          >
            Nouvelle facture
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-text-field
            v-model="search"
            class="me-3"
            dense
            label="Chercher facture"
            placeholder="Chercher une facture"
            outlined
          ></v-text-field>
          <v-select
            v-model="selectedStatus"
            clearable
            outlined
            dense
            :items="statusList"
            multiple
            chips
            item-text="text"
            item-value="statusName"
            label="Filtrer par status"
          >
          </v-select>
        </div>
      </v-sheet>
      <v-data-table
        v-if="invoices.length != 0"
        :headers="headers"
        :items="invoicesStatusFilter"
        :search="search"
        @dblclick:row="handleClick"
      >
        <!-- STATUS -->
        <template #[`item.status`]="{item}">
          <FacturationStatusChipVue :status="item.status"></FacturationStatusChipVue>
        </template>
        <!-- APPRENANT -->
        <template #[`item.student`]="{item}">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.student.prenom }} {{ item.student.nom }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getFormationName(item.student.formation) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- PRIX TOTAL -->
        <template #[`item.total`]="{item}">
          {{ Number(item.total).toLocaleString('fr-FR') }} €
        </template>
        <template #[`item.datefacturation`]="{item}">
          <div
            v-if="item.datefacturation"
            class="d-flex flex-column"
          >
            {{ new Date(item.datefacturation) | date('dd/MM/yyyy') }}
          </div>
          <div
            v-else
            class="d-flex flex-column"
          >
            A facturer
          </div>
        </template>
        <!-- DATE DE PAIEMENT -->
        <template #[`item.datereception`]="{item}">
          <div
            v-if="item.datereception"
            class="d-flex flex-column"
          >
            <div v-html="getDateReception(item.datereception)"></div>
          </div>
          <div
            v-else
            class="d-flex flex-column"
          >
            {{ item.status === 'billed' ? 'A venir' : 'Pas encore facturé' }}
          </div>
        </template>
        <!-- ACTION -->
        <template
          #[`item.actions`]="{item}"
          class="d-flex align-center justify-center"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                dark
                v-bind="attrs"
                @click="goToFile(item)"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Voir la facture</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="error"
                dark
                v-bind="attrs"
                @click="triggerDelete(item)"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </template>
            <span>Supprimer la facture</span>
          </v-tooltip>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(menuitem, i) in popmenu"
                :key="i"
                @click="menuClick(menuitem.text, item)"
              >
                <v-list-item-icon>
                  <v-icon
                    small
                    class="mt-1"
                    v-text="menuitem.icon"
                  ></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="menuitem.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon>
          </v-icon>
        </template>
      </v-data-table>
      <v-skeleton-loader
        v-else
        class="mx-auto"
        type="table"
      ></v-skeleton-loader>
    </v-card>
    <InvoiceDeleteDialog
      :delete-dialog="deleteDialog"
      :invoice="selectedInvoice"
      @closeDeleteDialog="deleteDialog = false"
      @deletedInvoice="deletedInvoice"
    ></InvoiceDeleteDialog>
    <InvoiceEditDialog
      :invoice-dialog="invoiceDialog"
      :edited-index="editedIndex"
      :edited-item="editedItem"
      :status-list="statusList"
      @closeInvoiceDialog="invoiceDialog = false"
      @invoiceUpdated="invoiceUpdated"
    ></InvoiceEditDialog>
  </div>
</template>

<script>
import {
  mdiEye, mdiTrashCan, mdiDotsVertical, mdiDownload, mdiPencil, mdiContentDuplicate, mdiAccount,
} from '@mdi/js'
import FacturationStatusChipVue from './FacturationStatusChip.vue'
import InvoiceEditDialog from './InvoiceEditDialog.vue'
import InvoiceDeleteDialog from './InvoiceDeleteDialog.vue'

export default {
  components: {
    FacturationStatusChipVue,
    InvoiceEditDialog,
    InvoiceDeleteDialog,
  },
  data() {
    return {
      deleteDialog: false,
      invoiceDialog: false,
      selectedInvoice: {
        student: {
          prenom: '',
          nom: '',
        },
      },
      statusFilterValue: '',
      search: '',
      editedIndex: -1,
      editedItem: {},
      invoices: [],
      formations: [],
      selectedStatus: '',
      popmenu: [
        { text: 'Télécharger', icon: mdiDownload },
        { text: 'Modifier', icon: mdiPencil },
        { text: 'Dupliquer', icon: mdiContentDuplicate },
        { text: 'Voir Profil', icon: mdiAccount },
      ],
      statusList: [
        {
          statusName: null,
          text: 'En attente',
        },
        {
          statusName: 'afacturer',
          text: 'A facturer',
        },
        {
          statusName: 'facture',
          text: 'Facturé',
        },
        {
          statusName: 'paiementrecu',
          text: 'Paiement reçu',
        },
      ],
      headers: [
        {
          text: 'Numéro',
          align: 'center',
          filterable: true,
          value: 'number',
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status',
        },
        {
          text: 'Apprenant',
          align: 'start',
          value: 'student',
        },
        {
          text: 'Total',
          align: 'center',
          value: 'total',
        },
        {
          text: 'Facturé le',
          align: 'center',
          value: 'datefacturation',
        },
        {
          text: 'Payé le',
          align: 'center',
          value: 'datereception',
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'actions',
        },
      ],
      icons: {
        mdiEye,
        mdiTrashCan,
        mdiDotsVertical,
        mdiAccount,
      },
    }
  },
  computed: {
    invoicesStatusFilter() {
      const { invoices } = this
      if (this.selectedStatus.length <= 0) {
        return invoices
      }

      return invoices.filter(x => this.selectedStatus.includes(x.status))
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchInvoices(), this.fetchFormations()])
        .then(values => {
          [this.invoices, this.formations] = values
          this.invoices = this.invoices.filter(x => x.student !== null)
          this.invoices.forEach(invoice => {
            invoice.prenom = invoice.student.prenom
            invoice.nom = invoice.student.nom
            invoice.formation = invoice.student.formation.nom
          })
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchInvoices() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/factures?_sort=number:DESC`)

      return response.data
    },
    async fetchFormations() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations-light`)

      return response.data
    },
    editDialog(item) {
      this.editedIndex = this.invoices.indexOf(item)
      this.editedItem = { ...item }
      this.invoiceDialog = true
    },
    goToFile(item) {
      return window.open(`${process.env.VUE_APP_API_URL}${item.pdf.url}`)
    },
    getFormationName(id) {
      const foundFormation = this.formations.find(x => x.id === id)

      return foundFormation.nom.substring(0, 15)
    },
    menuClick(menuitem, item) {
      switch (menuitem) {
        case 'Modifier':
          this.editDialog(item)

          return
        case 'Télécharger':
          fetch(`${process.env.VUE_APP_API_URL + item.pdf.url}`)
            .then(response => response.blob())
            .then(blob => {
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `Facture ${item.number} - ${item.student.nom} ${item.student.prenom}`
              link.click()
            })
            .catch(console.error)

          return
        case 'Voir Profil':
          this.$router.push({ name: 'student-single', params: { id: item.student.id } })

          return
        default:
          console.log(item)
          console.log(menuitem)
      }
    },
    getDateReception(datereception) {
      const niceDate = this.$date(new Date(datereception), 'dd/MM/yyyy')
      if (new Date(datereception) <= new Date()) {
        return niceDate
      }

      return `<span class="red">${niceDate}</span>`
    },
    handleClick(event, target) {
      console.log(event)
      this.$router.push({ name: 'invoice-single', params: { id: target.item.id } })
    },
    triggerDelete(item) {
      this.deleteDialog = true
      this.selectedInvoice = item
    },
    deletedInvoice(payload) {
      this.invoices = this.invoices.filter(x => x.id !== payload.id)
    },
    invoiceUpdated(payload) {
      const matchingIndex = this.invoices.findIndex(x => x.id === payload.id)
      this.invoices.splice(matchingIndex, 1, payload)
      this.invoiceDialog = false
    },

    /*  maraboutFacture() {
      this.invoices.forEach(invoice => {
        const total = invoice.student.price
        const datefacturation = invoice.updated_at
        let status = ''
        const result = new Date(invoice.updated_at)
        result.setDate(result.getDate() + 30)
        const datereception = new Date(result)
        if (invoice.number <= 771) {
          status = 'paiementrecu'
        } else {
          status = 'facture'
        }
        this.$http.put(`${process.env.VUE_APP_API_URL}/factures/${invoice.id}`, {
          total,
          status,
          datefacturation,
          datereception,
        })
          .then(res => {
            console.log(res)
          })
          .catch(err => console.log(err))
      })
    }, */
  },

}
</script>

<style>
  span.red{
    color: #ff4c51;
  }
</style>
