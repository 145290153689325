<template>
  <v-dialog
    v-model="deleteDialog"
    width="700"
    persistent
  >
    <v-card :loading="disabled">
      <v-card-title>
        <span class="text-h5">Supprimer la facture : {{ invoice.number }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        Vous allez supprimer la facture {{ invoice.number }} associée à {{ invoice.student.prenom }} {{ invoice.student.nom }}. <br>
        Êtes-vous sûr(e) ?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          text
          @click="$emit('closeDeleteDialog')"
        >
          Annuler
        </v-btn>
        <v-btn
          color="error"
          outlined
          :disabled="disabled"
          @click="deleteInvoice"
        >
          Supprimer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  props: {
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {
      disabled: false,
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    deleteInvoice() {
      this.disabled = true
      this.$http.delete(`${process.env.VUE_APP_API_URL}/factures/${this.invoice.id}`)
        .then(res => {
          if (res.status === 200) {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Facture supprimée avec succès',
              value: true,
            })
          }
          this.$emit('deletedInvoice', { id: this.invoice.id })
          this.$emit('closeDeleteDialog')
        })
        .catch(err => {
          console.log(err)
          this.disabled = false
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Une erreur est survenue pendant la suppression.',
            value: true,
          })
        })
        .finally(this.disabled = false)
    },
  },

}
</script>

<style>

</style>
