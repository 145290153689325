<template>
  <v-dialog
    v-model="invoiceDialog"
    persistent
    width="700"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Modification de la facture</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-if="editingStudent"
                :items="items"
                :append-icon="icons.mdiCheckboxMarkedCircle"
                label="Apprenant"
                @click:append="editingStudent = !editingStudent"
              >
              </v-select>
              <v-text-field
                v-else
                v-model="selectedStudentNames"
                dense
                outlined
                readonly
                :append-icon="icons.mdiPencil"
                label="Apprenant"
                @click:append="editingStudent = !editingStudent"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="editedItem.number"
                dense
                outlined
                label="Numéro de facture"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="editedItem.total"
                dense
                outlined
                :append-outer-icon="icons.mdiCurrencyEur"
                label="Total"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="durationHours"
                    dense
                    outlined
                    suffix="H"
                    :rules="[v => v!==0 || 'Nombre d\'heures requis']"
                    label="Durée de la formation "
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="durationMinutes"
                    dense
                    outlined
                    suffix="Min"
                    label="Durée de la formation "
                    :hint="calculateDuree"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="editedItem.status"
                dense
                outlined
                :items="statusList"
                item-text="text"
                item-value="statusName"
                label="Status"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                ref="menuDateFactu"
                v-model="menuDateFactu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFactuFormatted"
                    dense
                    outlined
                    label="Date de facturation"
                    :prepend-icon="icons.mdiCalendar"
                    v-bind="attrs"
                    @blur="dateFactu= parseDate(dateFactuFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFactu"
                  locale="fr"
                  no-title
                  @input="menuDateFactu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="editedItem.datereception"
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                ref="menuDateRecep"
                v-model="menuDateRecep"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRecepFormatted"
                    dense
                    outlined
                    label="Date de réception"
                    :prepend-icon="icons.mdiCalendar"
                    v-bind="attrs"
                    @blur="dateRecep= parseDate(dateRecepFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateRecep"
                  locale="fr"
                  no-title
                  @input="menuDateRecep = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          text
          @click="$emit('closeInvoiceDialog')"
        >
          Annuler
        </v-btn>
        <v-btn
          color="success"
          outlined
          @click="updateInvoice"
        >
          Mettre à jour
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiCurrencyEur, mdiPencil, mdiCheckboxMarkedCircle, mdiCalendar,
} from '@mdi/js'

export default {
  props: {
    invoiceDialog: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
    editedItem: {
      type: Object,
      default: () => {},
    },
    statusList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      icons: {
        mdiCurrencyEur,
        mdiPencil,
        mdiCheckboxMarkedCircle,
        mdiCalendar,
      },
      items: ['COUCOU', 'SALUT'],
      selectedStudent: '',
      editingStudent: false,
      menuDateFactu: false,
      durationHours: 0,
      durationMinutes: 0,
      menuDateRecep: false,
      dateFactu: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFactuFormatted: this.$date(new Date(), 'dd/MM/yyyy'),
      dateRecep: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateRecepFormatted: this.$date(new Date(), 'dd/MM/yyyy'),
      dateEcheance: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      selectedStudentNames: '',
      default: {
        number: 0,
        total: 0,
        student: '',
        status: '',
        datefacturation: new Date(),
        datereception: new Date(),
      },
    }
  },
  computed: {
    calculateDuree() {
      return `Soit ${this.durationHours} heures et ${this.durationMinutes} minutes`
    },
  },
  watch: {
    dateFactu(val) {
      this.dateFactuFormatted = this.formatDate(val)
      const date = new Date(val)
      this.dateEcheance = new Date(date.setDate(date.getDate() + 30)).toISOString().substr(0, 10)
    },
    invoiceDialog(val) {
      if (val) {
        if (this.editedItem.datefacturation) {
          this.dateFactuFormatted = this.$date(new Date(this.editedItem.datefacturation), 'dd/MM/yyyy')
          this.dateFactu = (new Date(new Date(this.editedItem.datefacturation) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }
        if (this.editedItem.datereception) {
          this.dateRecepFormatted = this.$date(new Date(this.editedItem.datereception), 'dd/MM/yyyy')
          this.dateRecep = (new Date(new Date(this.editedItem.datereception) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }
        this.selectedStudentNames = `${this.editedItem.student.nom} ${this.editedItem.student.prenom}`
        this.durationHours = Math.floor(this.editedItem.duration / 60)
        this.durationMinutes = this.editedItem.duration % 60
      }
    },
  },
  mounted() {
  },

  dateRecep(val) {
    this.dateRecepFormatted = this.formatDate(val)
  },
  methods: {
    updateInvoice() {
      const durationInMinutes = (parseInt(this.durationHours, 10) * 60) + parseInt(this.durationMinutes, 10)

      const invoiceData = {
        id: this.editedItem.id,
        dateFacturation: this.dateFactu,
        duration: durationInMinutes,
        total: this.editedItem.total,
        dateEcheance: this.dateEcheance,
        number: this.editedItem.number,
        student: this.editedItem.student,
        debutFormation: this.$date(new Date(this.editedItem.student.debutFormation), 'dd/MM/yyyy'),
        finFormation: this.$date(new Date(this.editedItem.student.finFormation), 'dd/MM/yyyy'),
      }
      console.log(invoiceData)

      // POST TO PDF GENERATOR
      this.$http.put(`${process.env.VUE_APP_API_URL}/factures/pdf/${this.editedItem.id}`, invoiceData)
        .then(res => {
          this.$emit('invoiceUpdated', res.data)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Facture mise à jour avec succès',
            value: true,
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 400) {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur sur le numéro de facture, rechargez la page.',
              value: true,
            })
          }
        })
        .finally()
    },
    formatDate(date) {
      if (!date) return null

      return this.$date(new Date(date), 'dd/MM/yyyy')
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },

}
</script>

<style>

</style>
